import { Component, useState } from "react";
import { Link } from "react-router-dom";
import i18next from '../../i18n'


import { withTranslation } from 'react-i18next';
const projectId = '5b8300db3a51ebe180e836ecdfc78863';


  
let socialList = [

    {
        iconName: 'fab fa-telegram',
        siteLink: 'https://t.me/MammonErc20',
    },
    {
        iconName: 'fab fa-twitter',
        siteLink: 'https://x.com/Mammon_ERC20',
    },

]





const changeLanguage = (newLanguage) => {
  i18next.changeLanguage(newLanguage);
};
class Header extends Component {


    closeMenu() {
        document.querySelector('.navbar-toggler').classList.add('collapsed');
        document.querySelector('.navbar-collapse').classList.remove('show');
    }


    render() { 
        const { t } = this.props;
        const btnText = t('header.buynow');
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header').classList.add(['header--fixed'], ['animated'], ['fadeInDown'])
            }else{
                document.querySelector('.header').classList.remove(['header--fixed'], ['animated'], ['fadeInDown'])
            }
        });


          

        return (
            <header className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-xl">
                        <Link className="navbar-brand" to="/#"><img src="assets/images/logo/logo.png" alt="Mammon" /></Link>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler--icon"></span> 
                        </button>

   
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className="nav-link" aria-current="page" href="#home" onClick={this.closeMenu}>{t('header.home')}</a>
                                <a className="nav-link" href="#roadmap" onClick={this.closeMenu}>{i18next.t('header.roadmap')}</a>
                                <a className="nav-link" href="#tokenomics" onClick={this.closeMenu}>{i18next.t('header.tokenomics')}</a>
                                <a className="nav-link" href="#connect" onClick={this.closeMenu}>{i18next.t('header.community')}</a>
                            </div>                       <div className="social-btns">
                                <ul className="social">
                                    {socialList.map((val, i) => (
                                        <li className="social__item" key={i}>
                                            <a target="_blank" href={val.siteLink} className="social__link"><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>        
                                <a target="_blank" href="https://app.uniswap.org/tokens/ethereum/0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6" className="default-btn">
                                    <span>{btnText}</span> 
                                </a>
                                
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default withTranslation()(Header);