import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';


let socialList = [

    {
        iconName: 'fab fa-telegram',
        siteLink: 'https://t.me/MammonErc20',
    },
    {
        iconName: 'fab fa-twitter',
        siteLink: 'https://x.com/Mammon_ERC20',
    },
]

class Footer extends Component {
    render() { 
        const { t } = this.props;
        const title = t('footer.part1');
const desc = i18next.t('footer.part2'); 
        return (
            <footer className="footer bg__theme-color">
                <div className="footer__wrapper padding-top padding-bottom">
                    <div className="container">
                        <div className="footer__content text-center">
                            <h3>{title}</h3>
                            <p>{desc}</p><p>mammonerc20@proton.me</p>
                            <LanguageSelector />
                            <ul className="social justify-content-center">
                                {socialList.map((val, i) => (
                                    <li className="social__item" key={i}>
                                        <a target="_blank" href={val.siteLink} className="social__link"><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default withTranslation()(Footer);