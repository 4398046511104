import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],

};
i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // pass the i18n instance to react-i18next.

  
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    
    debug: true,
    resources: {
      en: {
        translation: {
          signe: 'Authorization was successful.',
          connectWallet: 'Connect wallet',
          disconnect: 'Disconnect',
          balance: "Balance: {{balance}} MAM",
          signMessage: 'Sign Message',
          telegramButton: 'Telegram',
          community: {
            part1: 'Join The Mammon',
            part2: 'We aim to establish a robust decentralized community. Every token holder has the opportunity to participate in the governance of our community. To confirm ownership of tokens, we kindly request you to sign a one-time message expressing your intention to join our community using your Ethereum private key. Following this, you will become a full-fledged member of our Telegram group.'
          },
          header: {
            home: 'Home',
            roadmap: 'Roadmap',
            tokenomics: 'Tokenomics',
            howtobuy: 'How to Buy',
            buynow: 'Buy Now',
            community: 'Get tag "Holder"'
          },
          banner: {
            part1: '$MAM',
            part2: 'it’s time for Mammon'
          },
          about: {
            part1: 'ABOUT $MAM',
            part2: 'Step into the world of $MAM, an ERC-20 meme token that pays homage to the ancient deity Mammon. Named after the god of wealth and greed, Mammon embodies the spirit of prosperity in the blockchain realm.',
            part3: 'Mammon, the god of material wealth, invites you to join the Mammon community. Embrace the decentralized ethos of prosperity and indulge in the playful spirit of meme token trading. With $MAM, we\'re not just creating a token; we\'re building a divine economy where wealth flows freely.'
          },
          about2: {
            part1: 'Matthew 6:24',
            part2: '“No one can serve two masters, for either he will hate the one and love the other;',
            part3: 'or else he will be devoted to one and despise the other.',
            part4: 'You can’t serve both God and Mammon."'
          },
          roadmap: {
            part1: 'Roadmap',
            part2: '1. Emergence of Mammon',
            part3: 'Mammon rises to prominence as a deity associated with wealth and material abundance. Followers begin to recognize the allure of worldly possessions and the pursuit of financial success.',
            part4: '2. Zenith of Riches',
            part5: 'During this phase, Mammon reaches the pinnacle of adoration. The worship of wealth is fervent, and societies prioritize material gain as the ultimate pursuit. Mammon is celebrated as the bestower of prosperity and financial prowess.',
            part6: '3. Crisis of Avarice',
            part7: 'A period of internal turmoil unfolds as individuals grapple with the consequences of relentless pursuit of wealth. The realization dawns that materialism alone does not guarantee fulfillment, leading to a moral and existential crisis among Mammon\'s devotees.',
            part8: '4. Renunciation of Greed',
            part9: 'Some followers, disillusioned by the emptiness of unbridled avarice, choose to renounce their allegiance to Mammon. They embark on a quest for deeper meaning, seeking solace in spiritual or altruistic pursuits, distancing themselves from the relentless pursuit of riches.',
            part10: '5. Harmony and Balance',
            part11: 'The final stage marks a reconciliation between material aspirations and spiritual well-being. Mammon, now viewed as a symbol of balanced prosperity, is worshipped in moderation. Followers leverage their wealth for the greater good, fostering a harmonious coexistence of material success and spiritual enlightenment.'
          },
          howtobuy: {
            part1: 'How To Buy',
            part2: 'Create a Wallet',
            part3: 'Get some ETH',
            part4: 'Go to Uniswap',
            part5: 'Swap ETH for $MAM'
          },
          tokenomics: {
            part1: 'Tokenomics',
            part2: 'Symbol: $MAM',
            part3: 'Network: Ethereum (ERC-20)',
            part4: 'Contract: 0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6',
            part5: 'Token Supply: 666,000,000',
            part6: 'No Taxes, No Bullshit. It’s that simple.',
            part7: 'LP tokens are burnt.',
            part8: 'MORE ABOUT $MAM',
          },
          footer: {
            part1: 'Disclaimer',
            part2: '$MAM is purely a meme token created for entertainment purposes. While we pay tribute to Mammon, we encourage responsible and mindful participation in the crypto space.'
          }
        }
      },   
      cn: {
        translation: {
          signe: '授权成功.',
          connectWallet: '连接钱包',
          disconnect: '断开连接',
          balance: "余额：{{balance}} MAM",
          signMessage: '签名消息',
          telegramButton: 'Telegram',
          community: {
            part1: '加入玛门',
            part2: '我们的目标是建立一个强大的去中心化社区。每个代币持有者都有机会参与我们社区的治理。为了确认代币的所有权，我们诚挚地请求您使用您的以太坊私钥签署一条一次性消息，表达您加入我们社区的意愿。完成此步骤后，您将成为我们Telegram群体的全权成员。'
          },
          header: {
            home: '首页',
            roadmap: '路线图',
            tokenomics: '代币经济学',
            howtobuy: '如何购买',
            buynow: '立即购买',
            community: '社区'
          },
          banner: {
            part1: '$MAM',
            part2: '是时候了，玛门'
          },
          about: {
            part1: '关于 $MAM',
            part2: '踏入 $MAM 的世界，这是一种 ERC-20 的模因代币，向古代财神玛门致敬。以财富和贪婪之神玛门命名，$MAM体现了在区块链领域繁荣精神。',
            part3: '玛门，物质财富之神，邀请您加入玛门社区。拥抱繁荣的去中心化精神，沉浸在模因代币交易的嬉戏精神中。有了 $MAM，我们不仅仅是在创建一个代币；我们正在构建一个财富自由流动的神圣经济。'
          },
          about2: {
            part1: '马太福音 6:24',
            part2: '“没有人能事奉两个主，因为他或者恨这一个而爱那一个；',
            part3: '要不然就重这一个而轻那一个。',
            part4: '你们不能又事奉神又事奉玛门。”'
          },
          roadmap: {
            part1: '路线图',
            part2: '1. 玛门的出现',
            part3: '玛门作为与财富和物质丰富有关的神明崭露头角。追随者开始认识世俗财产的诱惑和追求财务成功。',
            part4: '2. 财富的巅峰',
            part5: '在这个阶段，玛门达到崇拜的巅峰。对财富的崇拜是热烈的，社会将物质收益视为终极追求。玛门被赞颂为繁荣和财务卓越的施赠者。',
            part6: '3. 贪婪的危机',
            part7: '随着个体因对财富的不懈追求而陷入内部动荡时期。人们逐渐认识到仅有物质主义并不能保证满足，导致在玛门的信徒中产生道德和存在危机。',
            part8: '4. 放弃贪婪',
            part9: '一些失望于无节制贪婪的追随者选择放弃对玛门的忠诚。他们踏上寻求更深层含义的征途，在精神或利他追求中寻求慰藉，远离对财富的不懈追求。',
            part10: '5. 和谐与平衡',
            part11: '最后阶段标志着对物质愿望和精神幸福的和解。现在将玛门视为平衡繁荣的象征，以适度的方式受到崇拜。追随者利用他们的财富为更大的善行贡献，促进物质成功和精神启蒙的和谐共存。'
          },
          howtobuy: {
            part1: '如何购买',
            part2: '创建钱包',
            part3: '获取一些 ETH',
            part4: '前往 Uniswap',
            part5: '用 ETHEREUM 交换 $MAM'
          },
          tokenomics: {
            part1: '代币经济学',
            part2: '符号: $MAM',
            part3: '网络: 以太坊 (ERC-20)',
            part4: '合约: 0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6',
            part5: '代币供应: 666,000,000',
            part6: '无税，无废话。就是这么简单。',
            part7: 'LP 代币已销毁。',
            part8: '更多关于 $MAM',
          },
          footer: {
            part1: '免责声明',
            part2: '$MAM 纯粹是为了娱乐而创建的模因代币。虽然我们向玛门致敬，但我们鼓励在加密空间中进行负责任和审慎的参与。'
          }
        }
      },   
      es: {
        translation: {
          signe: 'La autorización fue exitosa',
          connectWallet: 'Conectar billetera',
          disconnect: 'Desconectar',
          balance: "Saldo: {{balance}} MAM",
          signMessage: 'Firmar mensaje',
          telegramButton: 'Telegram',
          community: {
            part1: 'Únete a Mamón',
            part2: 'Nuestro objetivo es establecer una comunidad descentralizada sólida. Cada titular de token tiene la oportunidad de participar en la gobernanza de nuestra comunidad. Para confirmar la propiedad de los tokens, le solicitamos amablemente que firme un mensaje único expresando su intención de unirse a nuestra comunidad utilizando su clave privada de Ethereum. Después de esto, se convertirá en un miembro de pleno derecho de nuestro grupo de Telegram.'
          },
          header: {
            home: 'Inicio',
            roadmap: 'Hoja de Ruta',
            tokenomics: 'Tokenómica',
            howtobuy: 'Cómo Comprar',
            buynow: 'Comprar Ahora',
            community: 'Comunidad'
          },
          banner: {
            part1: '$MAM',
            part2: 'es hora de Mammon'
          },
          about: {
            part1: 'SOBRE $MAM',
            part2: 'Adéntrate en el mundo de $MAM, un token meme ERC-20 que rinde homenaje al antiguo dios Mammon. Nombrado en honor al dios de la riqueza y la codicia, Mammon encarna el espíritu de la prosperidad en el ámbito blockchain.',
            part3: 'Mammon, el dios de la riqueza material, te invita a unirte a la comunidad de Mammon. Abraza la ética descentralizada de la prosperidad y sumérgete en el espíritu lúdico del intercambio de tokens meme. Con $MAM, no estamos creando solo un token; estamos construyendo una economía divina donde la riqueza fluye libremente.'
          },
          about2: {
            part1: 'Mateo 6:24',
            part2: '“Ninguno puede servir a dos señores;',
            part3: 'porque o aborrecerá al uno y amará al otro, o estimará al uno y menospreciará al otro.',
            part4: 'No podéis servir a Dios y a las riquezas."'
          },
          roadmap: {
            part1: 'Hoja de Ruta',
            part2: '1. Emergencia de Mammon',
            part3: 'Mammon se eleva a la prominencia como un dios asociado con la riqueza y la abundancia material. Los seguidores comienzan a reconocer el atractivo de las posesiones mundanas y la búsqueda del éxito financiero.',
            part4: '2. Cima de las Riquezas',
            part5: 'Durante esta fase, Mammon alcanza la cúspide de la adoración. La veneración de la riqueza es ferviente, y las sociedades priorizan la ganancia material como la búsqueda definitiva. Mammon es celebrado como el otorgador de la prosperidad y la destreza financiera.',
            part6: '3. Crisis de la Avaricia',
            part7: 'Un período de agitación interna se desarrolla mientras las personas luchan con las consecuencias de la búsqueda implacable de la riqueza. Se da cuenta de que el materialismo por sí solo no garantiza la realización, lo que conduce a una crisis moral y existencial entre los devotos de Mammon.',
            part8: '4. Renuncia a la Codicia',
            part9: 'Algunos seguidores, desilusionados por la vacuidad de la avaricia desenfrenada, eligen renunciar a su lealtad a Mammon. Emprenden una búsqueda de un significado más profundo, buscando consuelo en empresas espirituales o altruistas, distanciándose de la búsqueda implacable de riquezas.',
            part10: '5. Armonía y Equilibrio',
            part11: 'La etapa final marca una reconciliación entre las aspiraciones materiales y el bienestar espiritual. Mammon, ahora visto como un símbolo de prosperidad equilibrada, es adorado con moderación. Los seguidores aprovechan su riqueza para el bien común, fomentando una convivencia armoniosa del éxito material y la iluminación espiritual.'
          },
          howtobuy: {
            part1: 'Cómo Comprar',
            part2: 'Crea una Billetera',
            part3: 'Obtén algo de ETH',
            part4: 'Ve a Uniswap',
            part5: 'Intercambia ETH por $MAM'
          },
          tokenomics: {
            part1: 'Tokenómica',
            part2: 'Símbolo: $MAM',
            part3: 'Red: Ethereum (ERC-20)',
            part4: 'Contrato: 0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6',
            part5: 'Suministro de Tokens: 666,000,000',
            part6: 'Sin Impuestos, Sin Tonterías. Es así de simple.',
            part7: 'LP son quemados.',
            part8: 'MÁS SOBRE $MAM',
          },
          footer: {
            part1: 'Descargo de responsabilidad',
            part2: '$MAM es puramente un token meme creado con fines de entretenimiento. Aunque rendimos homenaje a Mammon, fomentamos la participación responsable y consciente en el espacio criptográfico.'
          }
        }
      },
      ru: {
        
        translation: {
          signe: 'Авторизация прошла успешно.',
          connectWallet: 'Подключить кошелек',
          disconnect: 'Отключиться',
          balance: "Баланс: {{balance}} MAM",
          signMessage: 'Подписать сообщение',
          telegramButton: 'Телеграм',
          header: {
            home: 'Начало',
            roadmap: 'Дорожная карта',
            tokenomics: 'Токеномика',
            howtobuy: 'Как купить',
            buynow: 'Купить сейчас',
            community: 'Сообщество'
          },
          community: {
            part1: 'Присоединяйтесь к Мамоне',
            part2: 'Мы стремимся создать сильное децентрализованное сообщество. Каждый обладатель токенов имеет возможность участвовать в управлении нашим сообществом. Для подтверждения владения токенами просим вас единоразово подписать сообщение о намерении присоединиться к нашему сообществу с использованием вашего приватного ключа Ethereum. После этого вы станете полноправным участником в нашей группе в Telegram.'
          },
          banner: {
            part1: '$MAM',
            part2: 'Это время для Мамоны'
          },
          about: {
            part1: 'О $MAM',
            part2: 'Окунитесь в мир $MAM, мем-токена ERC-20, который почитает Мамону. Названный в честь богатства и жадности.',
            part3: 'Мамона, бог материального богатства, приглашает вас присоединиться к сообществу Мамона. Примите децентрализованное этическое преимущество процветания и погрузитесь в игривый дух торговли мем-токеном. С $MAM мы не просто создаем токен, мы строим божественную экономику, где богатство свободно течет.'
          },
          about2: {
            part1: 'Евангелие от Матфея 6:24',
            part2: '“Никто не может служить двум господам: ибо или одного будет ненавидеть, а другого любить;',
            part3: 'или одному станет усердствовать, а о другом нерадеть.',
            part4: 'Не можете служить Богу и маммоне."'
          },
          roadmap: {
            part1: 'Дорожная Карта',
            part2: '1. Появление Мамоны',
            part3: 'Мамона возвышается как божество, ассоциированное с богатством и материальным изобилием. Последователи начинают осознавать магию мирских владений и стремление к финансовому успеху.',
            part4: '2. Вершина богатства',
            part5: 'В этой фазе Мамона достигает пика поклонения. Поклонение богатству становится пылким и общество придает приоритет материальному достижению как крайней цели. Мамона отмечается как даритель процветания и финансовой независимости.',
            part6: '3. Кризис алчности',
            part7: 'Начинается период внутренних потрясений, поскольку люди сталкиваются с последствиями беспрекословного стремления к богатству. Приходит осознание, что один лишь материализм не гарантирует удовлетворения, что приводит к моральному и экзистенциальному кризису среди последователей Мамоны.',
            part8: '4. Отречение от жадности',
            part9: 'Некоторые последователи, разочарованные пустотой неудержимой алчности, предпочитают отречься от своей преданности Мамоне. Они отправляются в поисках глубокого смысла, ища утешение в духовных или альтруистических стремлениях, отдаляясь от беспрерывного стремления к богатству.',
            part10: '5. Гармония и баланс',
            part11: 'Последний этап отмечает примирение между материальными стремлениями и духовным благополучием. Мамона теперь рассматриваемый как символ сбалансированного процветания, поклоняются с умеренностью. Последователи используют свое богатство для блага, способствуя гармоничному сосуществованию материального успеха и духовного просвещения.'
          },
          howtobuy: {
            part1: 'Как купить',
            part2: 'Создать кошелек',
            part3: 'Получить ETH',
            part4: 'Пойти на Uniswap',
            part5: 'Обменять ETH на $MAM'
          },
          tokenomics: {
            part1: 'Токеномика',
            part2: 'Символ: $MAM',
            part3: 'Сеть: Ethereum (ERC-20)',
            part4: 'Контракт: 0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6',
            part5: 'Общее Количество: 666,000,000',
            part6: 'Нет налогов, нет обмана. Это простой контракт.',
            part7: 'Пул ликвидности сожжен.',
            part8: 'Больше о $MAM',
          },
          footer: {
            part1: 'Отказ от ответственности',
            part2: '$MAM - это мем-токен, созданный в развлекательных целях. Мы призываем к ответственному и вдумчивому участию в пространстве криптовалют.'
          },
          
        }
      }
    },
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    }
  });

export default i18n;