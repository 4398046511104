import { Component } from "react";


const title = "Mammon FAQ";
const desc = "Soon...";


class Faq extends Component {
    render() { 
        return (
            <section id="faq" className="faq padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>

                </div>
            </section>
        );
    }
}
 
export default Faq;