import { Component } from "react";
import i18next from '../../i18n'
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { withTranslation } from 'react-i18next';




let bannerSlider = [
   
]

class Banner extends Component {
    render() { 
        const { t } = this.props;
        const title = t('banner.part1');
        const desc = i18next.t('banner.part2');
        return (
            <section id="home" className="banner">
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row">
                            <div className="col">
                                <div className="banner__content" data-aos="zoom-in" data-aos-duration="1000">
                                    <h1>{title}</h1>
                                    <p>{desc}</p>
                                </div>
                                <div className="banner__thumb" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <div className="swiper banner__slider">
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={2}
                                            loop={'true'}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                            breakpoints={{
                                                0: {
                                                    width: 0,
                                                    slidesPerView: 1,
                                                },
                                                768: {
                                                    width: 768,
                                                    slidesPerView: 3,
                                                },
                                                1200: {
                                                    width: 1200,
                                                    slidesPerView: 4.7,
                                                },
                                            }}
                                        >
                                            {bannerSlider.map((val, i) => (
                                                <SwiperSlide key={i}>
                                                    <div className="banner__item">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(Banner);