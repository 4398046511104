import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';


class AboutTwo extends Component {
    render() { 
        const { t } = this.props;
        return (
            <section className="about padding-top padding-bottom bg__theme-color">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-right" data-aos-duration="1000">
                                    <h2>{t('about2.part1')}</h2>
                                    <p>{i18next.t('about2.part2')} </p>
                                    <p>{i18next.t('about2.part3')} </p><p>
                                    {i18next.t('about2.part4')}</p>
                               </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="zoom-in" data-aos-duration="1000">
                                    <img src="assets/images/about/01.png" alt="Art Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(AboutTwo);