import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AosInit from "./component/layout/aos";
import Footer from "./component/layout/footer";
import i18next from './i18n';
import Header from "./component/layout/header";
import About from "./component/section/about";
import Community from "./component/section/community";
import Bot from "./component/section/bot";
import Faq from "./component/section/faq";
import AboutTwo from "./component/section/about-2";
import Tokenomics from "./component/section/tokenomics";
import Banner from "./component/section/banner";
import Roadmap from "./component/section/roadmap";
import Howtobuy from "./component/section/howtobuy";

const BotApp = () => (
  <>
    <Bot />
    <Footer />
  </>
);

const NonBotApp = () => (
  <>
    <Header />
    <Banner />
    <About />
    <AboutTwo />
    <Roadmap />
    <Howtobuy />
    <Tokenomics />
    <Footer />
  </>
);

const App = () => {
  // Получение поддомена из текущего URL
  const subdomain = window.location.hostname.split('.')[0];

  // Определение компонента в зависимости от поддомена
  const AppToRender = subdomain === 'bot' ? BotApp : NonBotApp;

  return (
    <Router>
      <AosInit />
      <Routes>
        <Route path="/" element={<AppToRender />} />
      </Routes>
    </Router>
  );
}

export default App;
