import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';

const desc = "Entering The Penqueens Society means joining a family full of people who believe in the future of Cryptocurrencies and the Blockchain technology.";




class Roadmap extends Component {
    render() { 
        const { t } = this.props;
        const title = t('roadmap.part1');
        let roadmapList = [
            {
                className: 'roadmap__thumb',
                parent: '10%',
                title: i18next.t('roadmap.part2'),
                desc: i18next.t('roadmap.part3'),
            },
            {
                className: 'roadmap__thumb roadmap__thumb--bg-2',
                parent: '20%',
                title: i18next.t('roadmap.part4'),
                desc: i18next.t('roadmap.part5'),
            },
            {
                className: 'roadmap__thumb roadmap__thumb--bg-3',
                parent: '40%',
                title: i18next.t('roadmap.part6'),
                desc: i18next.t('roadmap.part7'),
            },
            {
                className: 'roadmap__thumb roadmap__thumb--bg-4',
                parent: '60%',
                title: i18next.t('roadmap.part8'),
                desc: i18next.t('roadmap.part9'),
            },
            {
                className: 'roadmap__thumb roadmap__thumb--bg-5',
                parent: '80%',
                title: i18next.t('roadmap.part10'),
                desc: i18next.t('roadmap.part11'),
            },
        ]
        
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>

                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row">
                            {roadmapList.map((val, i) => (
                                <div className="col-12" key={i}>
                                    <div className="roadmap__item" data-aos="fade-right" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className={val.className}>
                                                <span>{val.parent}</span>
                                            </div>
                                            <div className="roadmap__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(Roadmap);