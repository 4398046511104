import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <p>
      <button className="default-btn default-btn--rounded" onClick={() => changeLanguage('en')}>English</button>
      <button className="default-btn default-btn--rounded" onClick={() => changeLanguage('es')}>Español</button>
      <button className="default-btn default-btn--rounded" onClick={() => changeLanguage('ru')}>Русский</button>
      <button className="default-btn default-btn--rounded" onClick={() => changeLanguage('cn')}>中文</button></p>
    
  );
};

export default LanguageSelector;