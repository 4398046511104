import { Component } from "react";
import Webmodal from '../webmodal'
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';




class Bot extends Component {
    render() { 
        const { t } = this.props;
        const title = t('community.part1');
        const desc = t('community.part2');
        const btnText = "Join Our Discord";
        return (
            <section id="bot" className="about padding-top padding-bottom">
                <div className="container">
                <div className="section-header">
                        <h2>{title}</h2>

                    </div>
                    <div className="cconnect__wrapper">
                        <div className="about__content text-center" data-aos="zoom-in-up" data-aos-duration="1000">
                            <p>{desc}</p><Webmodal />
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(Bot);