import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';

class About extends Component {
    render() { 
        const { t } = this.props;
        return (
            <section className="about padding-top padding-bottom">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="fade-right" data-aos-duration="1000">
                                    <img src="assets/images/art/01.gif" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-left" data-aos-duration="1000">
                                    <h2>{t('about.part1')}</h2>
                                    <p>{i18next.t('about.part2')}</p>
                                    <p>{i18next.t('about.part3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(About);