import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalEvents,
  useWeb3ModalState,
  useWeb3ModalTheme,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useDisconnect
} from '@web3modal/ethers5/react';

const USDTAddress = '0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6';
const USDTAbi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) view returns (uint)",
  "function transfer(address to, uint amount)",
  "event Transfer(address indexed from, address indexed to, uint amount)"
];

const projectId = '5b8300db3a51ebe180e836ecdfc78863';
const chains = [
  {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com',
  },
];

const ethersConfig = defaultConfig({
  metadata: {
    name: 'Mammon',
    description: 'Mammon Coin',
    url: 'https://mammon.fun',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
  },
  defaultChainId: 1,
  rpcUrl: 'https://cloudflare-eth.com',
});

createWeb3Modal({
  tokens: {
    1: {
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
    },
    137: {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'
    }
  },
  ethersConfig,
  chains,
  enableAccountView: false,
  enableExplorer: false,
  projectId,
  enableNetworkView: false,
  enableAnalytics: false,

  themeMode: 'light',
  themeVariables: {
    '--w3m-color-mix': '#fff',
    '--w3m-color-mix-strength': 20,
    '--w3m-logo-image-url': 'https://mammon.fun/logocmc.png',
  },
});

export default function Webmodal() {
  const { t } = useTranslation();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const modal = useWeb3Modal();
  const { themeMode, themeVariables, setThemeMode } = useWeb3ModalTheme();
  const events = useWeb3ModalEvents();
  const [balance, setBalance] = useState(null);
  const { disconnect } = useDisconnect();
  const [isMessageSigned, setIsMessageSigned] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('usrid');
  console.log(userId);
  useEffect(() => {
    async function updateBalance() {
      if (isConnected) {
        try {
          const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const USDTContract = new ethers.Contract(USDTAddress, USDTAbi, signer);
          const USDTBalance = await USDTContract.balanceOf(address);
          setBalance(parseInt(ethers.utils.formatUnits(USDTBalance, 18)).toFixed(0));
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      }
    }

    updateBalance();
  }, [isConnected, walletProvider, address]);

  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueCode = '';

    for (let i = 0; i < 30; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueCode += characters.charAt(randomIndex);
    }

    return uniqueCode;
  };

  const [signature2, setSignature2] = useState(null);
  const [newCode2, setnewCode2] = useState(null);

  const handleLogout = () => {
    disconnect();
    setIsMessageSigned(false);
    setErrorMessage(null);
  };



  const signMessage = async () => {
    if (isConnected) {
      try {
        const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const message = 'With this message I confirm that I want to join the Mammon community. I agree that this message will be stored on the mammon.fun server.';
        const signature = await signer.signMessage(message);
        setIsMessageSigned(true);

        const newCode = generateUniqueCode();
        setSignature2(signature);
        setnewCode2(newCode);

        try {
          let requestBody = { address, signature, newCode };

          if (userId !== null) {
            requestBody.userId = userId;
          }

          const response = await fetch('https://api.mammon.fun:443/api/saveData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });

          const data = await response.json();
          console.log(data.success);
        } catch (error) {
          console.error('Error saving data:', error);
          setErrorMessage(error);
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage(error);
      }
    }
  };

  function LoginButton(props) {
    return (
      <button className="default-btn default-btn--rounded" onClick={() => modal.open()}>
        <span>{t('connectWallet')}</span>
      </button>
    );
  }

  function LogoutButton(props) {
    return (
      <>
        <p>{t('balance', { balance })}</p>
        {isMessageSigned ? (
          <p>
            {userId ? (
              errorMessage ? (
                <span>{errorMessage}</span>
              ) : (
                <span>{t('signe')}</span>
              )
            ) : (
              errorMessage ? (
                <span>{errorMessage}</span>
              ) : (
                <a href={`http://t.me/MammonErc20_bot?start=${newCode2}`} target="_blank" rel="noopener noreferrer">
                  <button className="default-btn default-btn--rounded">
                    <span><i className="fab fa-telegram" />Telegram</span>
                  </button>
                </a>
              )
            )}
          </p>
        ) : (
          <p>
            <button className="default-btn default-btn--rounded" onClick={signMessage}>
              <span>{t('signMessage')}</span>
            </button>
          </p>
        )}
        <p>
          <button className="default-btn default-btn--rounded" onClick={() => disconnect()}>
            <span>{t('disconnect')}</span>
          </button>
        </p>
      </>
    );
  }

  const button = isConnected ? <LogoutButton /> : <LoginButton />;

  return <>{button}</>;
}
