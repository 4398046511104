import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';

class Artist extends Component {
    render() { 
        const { t } = this.props;
        return (
            <section className="artist padding-top padding-bottom" id="tokenomics">
                <div className="container">
                    <div className="artist__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="artist__thumb" data-aos="fade-right" data-aos-duration="1000">
                                    <img src="assets/images/artist/01.png" alt="Artist Image" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="artist__content" data-aos="fade-left" data-aos-duration="1000">
                                    <h2 className="mb-4">{t('tokenomics.part1')}</h2>
                                    <p>{i18next.t('tokenomics.part2')}</p><p>
                                    {i18next.t('tokenomics.part3')}</p>
<p>{i18next.t('tokenomics.part4')}</p>
                                    <p>{i18next.t('tokenomics.part5')}</p>
                                    <p>{i18next.t('tokenomics.part6')}</p>
                                    <p className="mb-5">{i18next.t('tokenomics.part7')}</p>
                                    <a href="#" className="default-btn default-btn--rounded">
                                        <span>{i18next.t('tokenomics.part8')}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(Artist);