import { Component } from "react";
import i18next from '../../i18n'
import { withTranslation } from 'react-i18next';

const desc = "";





class Team extends Component {
    render() { 
        const { t } = this.props;
        const title = t('howtobuy.part1');
        let teamList = [
            {
                imgUrl: 'assets/images/team/01.png',
                imgAlt: i18next.t('howtobuy.part2'),
                name: i18next.t('howtobuy.part2'),
                degi: 'UI/UX Designer',
                social: [
                    {
                        iconImgUrl: 'assets/images/team/ethereum-eth-logo.png',
                        siteLink: 'https://ethereum.org/es/wallets/find-wallet/',
                    }
                ],
            },
            {
                imgUrl: 'assets/images/team/02.png',
                imgAlt: i18next.t('howtobuy.part3'),
                name: i18next.t('howtobuy.part3'),
                degi: 'Front End Designer',
                social: [
                    {
                        iconImgUrl: 'assets/images/team/ethereum-eth-logo.png',
                        siteLink: 'https://ethereum.org/en/get-eth/',
                    }
                ],
            },
            {
                imgUrl: 'assets/images/team/03.png',
                imgAlt: i18next.t('howtobuy.part4'),
                name: i18next.t('howtobuy.part4'),
                degi: 'UI/UX Designer',
                social: [
                    {
                        iconImgUrl: 'assets/images/team/uniswap-uni-logo.png',
                        siteLink: 'https://app.uniswap.org/tokens/ethereum/0x61280f046e11D4D9BB1aC25944Dd30516c47c5c6',
                    }
                ],
            },
            {
                imgUrl: 'assets/images/team/04.png',
                imgAlt: i18next.t('howtobuy.part5'),
                name: i18next.t('howtobuy.part5'),
                social: [
                    {
                        iconImgUrl: 'assets/images/team/logo.png',
                        siteLink: '#',
                    }
                ],
            },
        ]
        return (
            <section className="team padding-top padding-bottom bg__theme-color" id="howtobuy">
                <div className="container">
                    <div className="section-header" data-aos="fade-up" data-aos-duration="1000">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="team__wrapper">
                        <div className="row g-4">
                            {teamList.map((val, i) => (
                                <div className="col-lg-3 col-sm-6" key={i}>
                                    <div className="team__item" data-aos="fade-up" data-aos-duration="500">
                                        <div className="team__inner">
                                            <div className="team__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="team__content">
                                                <h4><a href="#">{val.name}</a></h4>
                                                
                                                <ul className="social-list g-10 d-flex justify-content-center">
                                                    {val.social.map((val, i) => (
                                                        <li className="social-item" key={i}>
                                                            <a href={val.siteLink} target="_blank"><img src={`${val.iconImgUrl}`} alt={`${val.imgAlt}`} /></a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default withTranslation()(Team);